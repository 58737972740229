import namehash from 'eth-ens-namehash';
import { utils } from 'ethers';
const { toUtf8Bytes, keccak256, concat, hexlify } = utils;

const VALID_CHARS_REGEX = /^[a-z0-9_-]*$/;
const Zeros = new Uint8Array(32);
Zeros.fill(0);

export const validateDomain = (domainName) => {
    if (domainName.length < 4) return 'Name length must be at least 4 characters';
    else if (!VALID_CHARS_REGEX.test(domainName)) return 'Name contains unsupported characters';

    return null;
};

export const renderDomain = (domain, maxLen = 20) => {
    if (domain && domain.length > maxLen) {
        const baseName = domain.split('.').pop();
        const baseLen = baseName.length;
        const length = maxLen / 2;
        return `${domain.slice(0, length)}...${domain.slice(-length, -(baseLen + 1))}.${baseName}`;
    }

    return domain;
};

export const renderPrice = (priceStr) => String(Math.floor(parseFloat(priceStr) * 1000) / 1000);

function nameSplit(name) {
    const bytes = toUtf8Bytes(name.toLowerCase());
    const comps = [];

    if (name.length === 0) { return comps; }

    let last = 0;
    for (let i = 0; i < bytes.length; i++) {
        const d = bytes[i];

        // A separator (i.e. "."); copy this component
        if (d === 0x2e) {
            comps.push(bytes.slice(last, i));
            last = i + 1;
        }
    }

    // There was a stray separator at the end of the name
    if (last >= bytes.length) { throw new Error("invalid name; empty component"); }

    comps.push(bytes.slice(last));
    return comps;
}

function getNameHash(name) {
    /* istanbul ignore if */
    if (typeof(name) !== "string") {
        throw new Error("invalid name; not a string", "name", name);
    }

    let result = Zeros;

    const comps = nameSplit(name);
    while (comps.length) {
        result = keccak256(concat([result, keccak256(comps.pop())]));
    }

    return hexlify(result);
}

export const calculateNameNode = (domain) => {
    try {
        return namehash.hash(domain);
    } catch (error) {
        if (error.message?.startsWith('Illegal char')) {
            return getNameHash(domain);
        } else {
            console.log('ERROR calculateNameNode:', { error });
        }
    }
}