import './css/progress-circle.css';

const ProgressCircle = () => (
    <div className='progress-circle'>
        <div className="wrapper" data-anim="base wrapper">
            <div className="circle" data-anim="base left"></div>
            <div className="circle" data-anim="base right"></div>
        </div>
    </div>
);

export default ProgressCircle;