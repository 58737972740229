import LoadingButton from './LoadingButton';

import './css/modal.css';

const Modal = ({ handleClose, buttonProps, actionHandler, title, show, content }) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	const buttonDisabled = !Boolean(actionHandler);

	return (
		<div className={showHideClassName}>
			<section className="modal-main">
				<div className="icon icon-close" onClick={handleClose} />
				<div className="title">{title}</div>
				<div className="content">
					{content}
				</div>        
				<div className="btn-group">
					<LoadingButton
						actionHandler={actionHandler}
						className={`btn-primary${buttonDisabled ? ' disabled' : ''}`}
						label={buttonProps.name}
						style={{ width: '220px' }}
					/>
				</div>
			</section>
		</div>
	);
}

export default Modal;
