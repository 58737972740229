import { useCallback, useState } from "react";
import { useAccount } from "wagmi";

import Modal from "./Modal";

import './css/address-edit.css';

const REGEX = /^0x[a-fA-F0-9]{40}$/;

const AddressEdit = ({ handleClose, domain, associatedAddr, setAddressForDomain, disabledEdit }) => {
	const { address } = useAccount();
	const [isEditing, setIsEditing] = useState(false);
	const [newAddr, setNewAddr] = useState(address);
	const [isValid, setIsValid] = useState(true);
	const isMatch = address === associatedAddr;

	const toggleEdit = useCallback(() => {
		if (disabledEdit) return;
		setIsEditing(!isEditing)
	}, [disabledEdit, isEditing]);

	const handleNameChange = useCallback((e) => {
		const newValue = e.target.value;
		setNewAddr(newValue);
		setIsValid(REGEX.test(newValue));
	}, []);

	const handleSetAddressForDomain = useCallback(async () => {
		if (!isValid) return;
		await setAddressForDomain(newAddr, domain);
		setIsEditing(false);
	}, [isValid, domain, newAddr, setAddressForDomain])

	const renderContent = () => {
		if (isEditing) {
			return (
				<div className="input-group">
					<input
						className="value-input address-input"
						onChange={handleNameChange}
						placeholder="Enter Wallet Address here..."
						value={newAddr}
					/>
					<div className={`icon icon-${isValid ? 'check' : 'warn'}`}></div>
				</div>
			);
		}

		return (
			<div className="address-display">
				{!isMatch && <p>Only WENS names that point to your ETHW account can be set as your Primary Name.</p>}
				<p className={isMatch ? '' : 'warning'}><span className={`icon icon-${isMatch ? 'checked' : 'warning'}`} />{associatedAddr}</p>
			</div>
		)
	};

	return (
		<Modal
			title={domain}
			content={renderContent()}
			buttonProps={{
				name: isEditing
					? (newAddr === associatedAddr ? 'No Change' : 'Confirm')
					: 'Set Address'
			}}
			show
			actionHandler={
				isEditing
					? ( !isValid || newAddr === associatedAddr ? null : handleSetAddressForDomain)
					: (disabledEdit ? null : toggleEdit)
			}
			handleClose={handleClose}
		/>
	);
}

export default AddressEdit;
