
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import copy from 'copy-to-clipboard';

import { AccountContext } from '../hooks/AccountContext';

import useSupportedNetwork from '../hooks/useSupportedNetwork';

import { renderDomain } from '../helpers/domain-helpers';
import { renderAddress } from '../helpers/address-helpers';

import MyAccount from './MyAccount';
import LoadingButton from './LoadingButton';
import MarketLink from './MarketLink';
import AddressEdit from './AddressEdit';
import Loading from './Loading';

import './css/my-domains.css';


const MyDomains = () => {
    const {
        address,
        addressByDomain,
        defaultName,
        fetchAddress,
        isConnected,
        isLoading,
        setAddressForDomain,
        tokens,
        updateDefaultName,
    } = useContext(AccountContext);
    
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isFetchingAddress, setIsFetchingAddress] = useState(false);
    const [showAddressEdit, setShowAddressEdit] = useState(false);
    const { isWrongNetwork, renderSwitchButton } = useSupportedNetwork();
    const is980 = useMediaQuery({ query: '(max-width: 980px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const makeSelectToken = useCallback((index) => () => {
        setSelectedIndex(index);
    }, []);


    const initiateFetchAddress = useCallback(async (domain) => {
        setIsFetchingAddress(true);
        await fetchAddress(domain);
        setIsFetchingAddress(false);
    }, [fetchAddress]);

    useEffect(() => {
        const { domain = '' } = tokens[Math.max(selectedIndex, 0)] || {};
        if (domain && !addressByDomain[domain]) {
            initiateFetchAddress(domain);
        }
    }, [tokens, selectedIndex, addressByDomain, initiateFetchAddress]);

    const handleSetDefaultName = useCallback(async () => {
        if (!address) return;
        const { domain } = tokens[Math.max(selectedIndex, 0)];
        await updateDefaultName(address, domain);
    }, [address, selectedIndex, tokens, updateDefaultName]);

    const renderSelected = () => {
        const { domain = '', expireTime, basename = '' } = tokens[Math.max(selectedIndex, 0)] || {};
        const displayExpireTime = expireTime ? new Date(expireTime * 1000).toUTCString() : '--';
        const isDefaultName = defaultName && domain === defaultName;
        const associatedAddr = addressByDomain[domain];
        const isAddressMatchCurrentUser = associatedAddr === address;
        const CTA = isDefaultName ? null : (!isAddressMatchCurrentUser ? () => setShowAddressEdit(true) : handleSetDefaultName);
        const CTALabel = isDefaultName ? 'Primary Name' : 'Set as Primary Name';
        return (
            <>
                <div className={`nft-image ${basename}`}></div>
                <div className="nft-text">
                    {renderDomain(domain)}
                    {domain.length > 20 && <span className='tooltiptext'>{domain}</span>}
                </div>
                <div className="token-details">
                    <div className="text-item">
                        <div className="heading">REGISTRANT</div>
                        <div className="text">
                            {
                                is980 ? renderAddress(address) : address
                            }
                            &nbsp;<div className="icon icon-copy" onClick={() => copy(address)}/></div>
                    </div>
                    <div className="text-item">
                        <div className="heading">CONTROLLER</div>
                        <div className="text">
                            {
                                is980 ? renderAddress(address) : address
                            }
                            &nbsp;<div className="icon icon-copy" onClick={() => copy(address)}/></div>
                    </div>
                    <div className="text-item">
                        <div className="heading">EXPIRY DATE</div>
                        <div className="text nowrap">{displayExpireTime}</div>
                    </div>
                    <div className="text-item">
                        <div className="heading nowrap">ADDRESS &nbsp;<div className="icon icon-edit" onClick={() => setShowAddressEdit(true)}/></div>
                        <div className="text nowrap">
                            {
                                is980 ? renderAddress(associatedAddr, 20) : associatedAddr
                            }
                            &nbsp;<div className="icon icon-copy" onClick={() => copy(associatedAddr)}/></div>
                    </div>
                </div>
                <div className="btn-group">
                    {domain && (
                        <>
                            <LoadingButton
                                actionHandler={CTA}
                                className={`btn-primary${isDefaultName ? ' disabled' : ''}`}
                                isPreparing={isFetchingAddress}
                                label={CTALabel}
                                style={{ width: '220px' }}
                            />
                            <MarketLink useContextMenu={!isMobile} tether='up' />
                        </>
                    )}
                </div>
            </>
        )
    }

    const renderEmptyState = () => (
        <>
            <div className="nft-image blank"></div>
            <div className="nft-text">
                No domain name yet
            </div>
            <div className="text-item">
                <div className="heading">&nbsp;</div>
                <div className="text">You could go back to homepage to search a domain name to register, or buy in the marketplace.</div>
            </div>
            <div className="btn-group">
                <Link to="/">
                    <div className='btn btn-primary'>Register A Name</div>
                </Link>
                <MarketLink  useContextMenu={!isMobile} tether='up' />
            </div>
        </>
    );
    
    const renderTokens = (tokens) => (
        <>
            {tokens.map(({ domain, basename }, index) => (
                <div
                    key={domain}
                    className={`my-domains-item nft-${basename} ${index === selectedIndex ? 'my-domains-item-selected' : ''}`}
                    onClick={makeSelectToken(index)}
                >
                    {renderDomain(domain)}
                    <span className="icon icon-arrow" />
                </div>
            ))}
        </>
    );

    const renderEmptyToken = () => (
        <div
            className={`my-domains-item empty-token ${selectedIndex >= 0 ? 'my-domains-item-selected' : ''}`}
            onClick={makeSelectToken(0)}
        >
            None
            <span className="icon icon-arrow" />
        </div>
    );

    const renderAddressEdit = () => {
        if (!showAddressEdit) return null;
        const { domain = '' } = tokens[Math.max(selectedIndex, 0)] || {};
        const associatedAddr = addressByDomain[domain];
        const isDefaultName = defaultName && domain === defaultName;
        return (
            <AddressEdit
                domain={domain}
                associatedAddr={associatedAddr}
                handleClose={() => setShowAddressEdit(false)}
                setAddressForDomain={setAddressForDomain}
                disabledEdit={isDefaultName}
            />
        );
    }

    if (isWrongNetwork) {
        return renderSwitchButton();
    }
    if (isConnected && isLoading) return (<Loading />);

    return (
        <div className='my-domains'>
            <div className="my-domains-container">
                {
                    !isMobile || selectedIndex < 0 ?
                    (
                        <div className="my-domains-collections">
                            <MyAccount address={address} defaultName={defaultName} hasTokens={tokens && tokens.length} />
                            <div className='nft-container'>
                                {tokens.length ? renderTokens(tokens) : renderEmptyToken()}
                            </div>
                        </div>
                    ) : ''
                }
                {
                    !isMobile || selectedIndex >= 0 ? 
                    (
                        <div className="my-domains-display">
                            <div className="icon icon-close" onClick={makeSelectToken(-1)} />
                            {tokens.length ? renderSelected() : renderEmptyState()}
                        </div>
                    ) : ''
                }
            </div>
            {renderAddressEdit()}
        </div>
    );
}

export default MyDomains;