import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useDisconnect } from 'wagmi';

import { AccountContext } from '../hooks/AccountContext';
import MyAccount from './MyAccount';

import './css/header.css';

const Header = () => {
    const { isConnected, defaultName, tokens } = useContext(AccountContext);
    const { disconnect } = useDisconnect();
    const { openConnectModal } = useConnectModal();
    const [showMyAccount, setShowMyAccount] = useState(false);
    const hasOpenMysteryBox = localStorage.getItem('hasOpenMysteryBox') || false;

    const renderAccount = () => (
        <Link className="nav-icon" to="/mydomains">
            <button
                className='btn btn-secondary no-border'
                type="button"
                // onMouseEnter={() => setShowMyAccount(true)}
            >
                <div className="icon-account" />
            </button>
        </Link>
    );

    const renderMysteryBox = () => (
        <Link className="nav-icon" to="/mysterybox">
            <button
                className='btn btn-secondary no-border'
                type="button"
            >
                <div className="icon-mysterybox">
                    {hasOpenMysteryBox ? null : <span className='notification-dot'>2</span>}
                </div>
            </button>
        </Link>
    );

    const renderLogout = () => (
        <Link className="nav-icon" onClick={disconnect} to="/">
            <button
                className='btn btn-secondary no-border'
                type="button"
            >
                <div className="icon-logout" />
            </button>
        </Link>
    );

    const renderConnectButton = () => (
        <button
            className='btn btn-secondary'
            onClick={openConnectModal}
            // onMouseEnter={() => setShowMyAccount(true)}
            type="button"
        >
            Connect<span> wallet</span>
        </button>
    );

    return (
        <div className="header">
            <Link to="/" >
                <div className="header-title">
                    <div className="logo" />
                    <div className="title">WENS</div>
                </div>
            </Link>
            <div className="header-buttons">
                {isConnected
                    ? (
                        <>
                            {renderMysteryBox()}
                            {renderAccount()}
                            {renderLogout()}
                        </>
                    )
                    : renderConnectButton()
                }
            </div>
            { showMyAccount ? <MyAccount defaultName={defaultName} hasTokens={tokens && tokens.length} onMouseLeave={() => setShowMyAccount(false)} /> : ''}
        </div>
    )
};

export default Header;
