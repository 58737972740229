import { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { useAccount, useSigner } from "wagmi";

import useNFTContracts from './useNFTContracts';
import useWENSNames from './useWENSNames';

import { REGISTRARS } from '../constants/contracts';

export const AccountContext = createContext();

export const useAccountContext = () => useContext(AccountContext);

export const AccountContextProvider = ({ children }) => {
  const { isConnected, address } = useAccount();
  const { data: signer } = useSigner();
	const { addressByDomain, defaultName, fetchAddress, setAddressForDomain, updateDefaultName } = useWENSNames();
  const contracts = useNFTContracts();
  const [tokens, setTokens] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchNFT = useCallback(async ({ basename, address: registrarAddress }) => {
		if (!isConnected || !address) return [];

		const contract = contracts[basename];
		if (!contract) return [];

		const balance = (await contract.balanceOf(address)).toNumber();
		if (!balance) return [];

    const newTokens = await Promise.all(
      [...Array(balance).keys()].map(
        async (index) => {
          const tokenId = await contract.tokenOfOwnerByIndex(address, index);
          const expireTime = (await contract.nameExpires(tokenId)).toNumber();
          try {
            const name = await contract.getName(tokenId);
            const domain = `${name}.${basename}`;
              return { domain, expireTime, basename, registrarAddress };
          } catch (error) {
            console.log('ERROR fetch NFT', { basename, error });

            const domain = `#${tokenId}.${basename}`;
            return { domain, expireTime, basename, registrarAddress };
          }
        }
      )
    );
		return newTokens;
  }, [address, contracts, isConnected]);

  const fetchNFTs = useCallback(async() => {
      if (!signer || !isConnected || !address) return;

      const newTokens = (await Promise.all(REGISTRARS.map(fetchNFT)))
          .reduce((allDomains, current) => {
              current.forEach((token) => allDomains.push(token));
              return allDomains
          }, []);
      setTokens(newTokens);
      setIsLoading(false);
  }, [address, fetchNFT, isConnected, signer]);

  useEffect(() => {
    fetchNFTs();
  }, [address, fetchNFTs]);

  return (
    <AccountContext.Provider value={{
      address,
      addressByDomain,
      defaultName,
      fetchAddress,
      isConnected,
      isLoading,
      setAddressForDomain,
      setTokens,
      tokens,
      updateDefaultName,
    }}>
      {children}
    </AccountContext.Provider>
  );
};
