import { useCallback, useEffect, useState } from 'react';
import {
    useAccount,
    useContract,
    useSigner,
} from 'wagmi';
import { Buffer } from 'buffer';

import { calculateNameNode } from '../helpers/domain-helpers';

import { REVERSE_RESOLVER_ADDR, REVERSE_REGISTRAR_ADDR, RESOLVER_ADDR } from '../constants/contracts';
import { REVERSE_RESOLVER_ABI, REVERSE_REGISTRAR_ABI, PUBLIC_RESOLVER_ABI } from '../constants/abis';

// Webpack version 5 does not support Buffer
window.Buffer = window.Buffer || Buffer;

const useWENSNames = () => {
    const { isConnected, address } = useAccount();
    const { data: signer } = useSigner();
    const [defaultName, setDefaultName] = useState();
    const [addressByDomain, setAddressByDomain] = useState({});
    const reverseResolverContract = useContract({
        addressOrName: REVERSE_RESOLVER_ADDR,
        contractInterface: REVERSE_RESOLVER_ABI,
        signerOrProvider: signer,
    });
    const reverseRegistrarContract = useContract({
        addressOrName: REVERSE_REGISTRAR_ADDR,
        contractInterface: REVERSE_REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const resolverContract = useContract({
        addressOrName: RESOLVER_ADDR,
        contractInterface: PUBLIC_RESOLVER_ABI,
        signerOrProvider: signer,
    })

    const getReverseNode = useCallback(() =>
        calculateNameNode(address.slice(2).toLowerCase() + '.addr.reverse')
    , [address]);

    const fetchDefaultName = useCallback(async () => {
        if (!signer || !address || !reverseResolverContract.name) return;

        try {
            const reverseNode = getReverseNode(address);
            const newDefaultName = await reverseResolverContract.name(reverseNode);
            setDefaultName(newDefaultName);
        } catch (error) {
            console.log('ERROR fetch default name:', { error });
        }
    }, [address, reverseResolverContract, getReverseNode, signer]);

    const fetchAddress = useCallback(async (domain) => {
        if (!signer || !domain) return;
        
        try {
            const nameNode = calculateNameNode(domain);
            const associatedAddress = await resolverContract['addr(bytes32)'](nameNode);
            setAddressByDomain({
                ...addressByDomain,
                [domain]: associatedAddress,
            });
        } catch (error) {
            console.log('ERROR fetch associated address:', { error });
        }
    }, [addressByDomain, resolverContract, signer]);

    const setAddressForDomain = useCallback(async (newAddress, newDomain) => {
        if (!signer || !newDomain || !newAddress) return;
        const nameNode = calculateNameNode(newDomain);

        try {
            const tx = await resolverContract['setAddr(bytes32,address)'](nameNode, newAddress);
            await tx.wait();
            setAddressByDomain({
                ...addressByDomain,
                [newDomain]: newAddress,
            });
        } catch (error) {
            console.log('ERROR set address for domain:', { error });
        }
    }, [addressByDomain, resolverContract, signer]);

    useEffect(() => {
        if (isConnected && address && reverseResolverContract) {
            fetchDefaultName();
        }
    }, [isConnected, address, fetchDefaultName, reverseResolverContract]);

    const updateDefaultName = useCallback(async (newAddress, newDefaultName) => {
        if (!newAddress || !newDefaultName || newDefaultName === defaultName) return;

        try {
            const tx = await reverseRegistrarContract.setNameForAddr(newAddress, address, newDefaultName);
            await tx.wait();
            setDefaultName(newDefaultName);
        } catch (error) {
            console.log('ERROR set default name:', { error });
        }
    }, [reverseRegistrarContract, address, defaultName]);

    return {
        addressByDomain,
        defaultName,
        fetchAddress,
        setAddressForDomain,
        updateDefaultName,
    };
};

export default useWENSNames;
