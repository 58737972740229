import { useCallback, useState } from 'react';

import './css/loading-button.css';

const LoadingButton = ({
    actionHandler,
    label,
    className = 'btn-primary',
    style = {},
    disabled = false,
    isPreparing = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const handleClick = useCallback(async() => {
        if (isLoading || !actionHandler || disabled) return;
        setIsLoading(true);
        try {
            await actionHandler();
        } catch (error) {
            console.log('ERROR:', { error });
        }
        setIsLoading(false);
    }, [actionHandler, isLoading, disabled]);

    return (
        <div
            className={`btn btn-loading ${className}${disabled ? ' disabled' : ''}${isLoading ? ' loading' : ''}`}
            onClick={handleClick}
            style={style}
        >
            {(!isLoading && !isPreparing) ? label : (<div className='icon icon-loading'></div>)}
        </div>
    )
}

export default LoadingButton;