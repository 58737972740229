
import { Link } from 'react-router-dom';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from 'wagmi';

import { renderDomain } from '../helpers/domain-helpers';
import { renderAddress } from '../helpers/address-helpers';

import './css/my-account.css';

const MyAccount = (props) => {
  const { defaultName, hasTokens = false } = props;

  const { openConnectModal } = useConnectModal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();

  const renderHighlight = () => (
    hasTokens ? renderDomain(defaultName) || 'Set a name!' : <Link to="/">Register a name!</Link>
  );

  return (
    <div className="my-account-card" onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}>
        <div className="icon icon-person" />
        <div className="nft-details">
            <div className="nft-details-heading">
              {
                isConnected ?
                  <>
                    <Link to="/mydomains">{address ? renderAddress(address, 12) : '--'}</Link>&nbsp;
                    <div className='icon icon-logout' onClick={disconnect} />
                  </> : 'Not connected'
              }
            </div>
            <div className="nft-details-text">Primary WENS Name</div>
            <div className="nft-details-heading highlight">
                { isConnected ? renderHighlight() : <Link onClick={openConnectModal}>Connect wallet</Link> }
            </div>
        </div>
    </div>
  )
}

export default MyAccount;