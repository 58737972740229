import { ethers } from 'ethers';
import { useState, useCallback, useEffect } from 'react';
import { useAccount } from "wagmi";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import LoadingButton from './LoadingButton.js';
import MarketLink from './MarketLink.js';
import ProgressCircle from './ProgressCircle.js';

import { RESOLVER_ADDR } from '../constants/contracts.js';
import { renderDomain, renderPrice } from '../helpers/domain-helpers.js';

import './css/registration-wizard.css';

// 60 * 60 * 24 * 365
const YEAR_UNIT = 31536000;

const RegistrationWizard = ({
    closeModal,
    contract,
    isDurationEditable = true,
    isOpen,
    isSingleDomain = false,
    name,
    registrars,
    resetSearch,
    selectedIndex,
}) => {
    console.log('QA: wizard', { name, selectedIndex, contract, registrars })
    const { address } = useAccount();
    const [params, setParams] = useState([]);
    const [registration, setRegistration] = useState({});
    const [step, setStep] = useState(1);
    const [durationInYear, setdurationInYear] = useState(1);
    const [registrationState, setRegistrationState] = useState({});
    const [selectedRegistrars] = useState(registrars.filter((_, index) => selectedIndex.has(index)));
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        const updateRegistrationState = async () => {
            const size = selectedRegistrars.length;
            const duration = YEAR_UNIT * durationInYear;
            const registrarAddresses = selectedRegistrars.map((registrar) => registrar.address);
            console.log('QA: fetch', { name, size, duration, registrarAddresses });
            let price;
            try {
                if (isSingleDomain) price = await contract.rentPrice(name, duration);
                else price = price = await contract.rentPrice(name, duration, registrarAddresses);
                console.log('QA: price', price);
                setRegistrationState({
                    duration,
                    name,
                    price,
                    registrarAddresses,
                    size,
                });
            } catch (err) {
                console.log('QA: error', { err });
            }
        }
        if (name && contract) {
            updateRegistrationState();
        }
    }, [durationInYear, name, isSingleDomain, contract, selectedRegistrars]);

    const handleBack = useCallback(() => {
        if (step === 5) {
            resetSearch();
        }
        closeModal();
    }, [step, closeModal, resetSearch])

    const handleChangeDuration = useCallback((delta) => () => {
        if (step !== 1) return;

        const newDurationInYear = durationInYear + delta;
        if (newDurationInYear >= 1) {
            setdurationInYear(newDurationInYear);
        }
    }, [durationInYear, step]);

    const handleRegister = useCallback(async () => {
        if ((step !== 3 && step !== 5) || !params.length) return;

        try {
            const trxResp = await contract.register(...params);
            await trxResp.wait();
            setParams([]);
            setRegistration({ success: true });
            setRegistrationState({});
            setStep(5);
        } catch (error) {
            console.log('QA: ERROR', { error });
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                const overrides = params.pop();
                const trxResp = await contract.register(...params, { ...overrides, gasLimit: 8000000 });
                await trxResp.wait();
                setParams([]);
                setRegistration({ success: true });
            } else {
                setRegistration({ success: false });
            }
            setStep(5);
        }
    }, [contract, step, params]);

    const handleCommit = useCallback(async () => {
        if (!registrationState.price) return;

        const { duration, name, price, registrarAddresses } = registrationState;
        // Generate a random value to mask our commitment
        const random = new Uint8Array(32);
        crypto.getRandomValues(random);
        const salt = "0x" + Array.from(random).map(b => b.toString(16).padStart(2, "0")).join("");
        // Submit our commitment to the smart contract
        const commitment = await contract.makeCommitment(name, address, salt, RESOLVER_ADDR);
        const trx = await contract.commit(commitment);
        await trx.wait();
        setStep(2);
        const params = [name, address, duration, salt, RESOLVER_ADDR];
        if (!isSingleDomain) {
            params.push(registrarAddresses);
        }
        setParams([...params, { value: price }]);
        // Wait 60 seconds before registering
        setTimeout(async () => {
            setStep(3);
        }, 60000);
    }, [address, contract, isSingleDomain, registrationState]);

    if (!isOpen) return null;

    const { size, price } = registrationState;
    let displayPrice = price ? renderPrice(ethers.utils.formatEther(price)) : '--';
    if (displayPrice.startsWith('0.00')) {
        displayPrice = '< 0.01'
    }

    const renderDomainPills = () => (
        <div className='domain-pill-container'>
            {selectedRegistrars.map(({ basename }) => (
                <div key={basename} className={`domain-pill nft-${basename}`}>{renderDomain(`${name}.${basename}`)}</div>
            ))}
        </div>
    );

    const renderRegistration = () => (
        <>
            {(step === 1 || step === 2) && <h3>Step 1: Request to Register</h3>}
            {(step === 3 || step === 4) && <h3>Step 2: Complete Registration</h3>}

            {(step === 1 || step === 2) && (
                <>
                    <div className='registration-summary'>
                        <div className='registration-variable'>{size} <span className='unit'>{`Name${size > 1 ? 's' : ''}`}</span></div>
                        <span>X</span>
                        <div className='registration-variable year'>
                            {isDurationEditable ? <span className='operator' onClick={handleChangeDuration(-1)}>-</span> : <span/>}
                            <span>{durationInYear} <span className='unit'>{`Year${durationInYear > 1 ? 's' : ''}`}</span></span>
                            {isDurationEditable ? <span className='operator' onClick={handleChangeDuration(+1)}>+</span> : <span/>}
                        </div>
                        <span>Total</span>
                        <div className='registration-total'>{displayPrice} ETHW</div>
                    </div>
                    {isDurationEditable &&
                        <div className='registration-price-tag'>
                            Registration Fee: 0.5 ETHW / Year
                        </div>
                    }
                    <div className='registration-description'>
                        In this step, you may request for registration and perform the first of the two transactions. Upon requesting, the system will undergo a process to ensure other users are not trying to register for the same domain and protect you after request. This may take up to 60 seconds.
                    </div>
                </>
            )}
            {(step === 3 || step === 4) && (
                <>
                    <div className='registration-description'>
                        Confirm the registration, perform payment and complete the registration. Please note that if the second transaction is not processed within 7 days after the first, the registration will be forfeited and has to be restarted from the first step.
                    </div>
                    {renderDomainPills()}
                </>
            )}
            
            {(step === 1 && params.length === 0) &&
                <div className='btn-group'>
                    {!isMobile && <div className={`btn btn-secondary`} onClick={handleBack}>Back</div>}
                    <LoadingButton
                        className='btn-primary'
                        actionHandler={handleCommit}
                        label="Request"
                        style={{ width: '150px' }}
                    />
                </div>
            }
            {step === 2 && <ProgressCircle />}
            {step === 3 && (
                <LoadingButton
                    className='btn-primary'
                    actionHandler={handleRegister}
                    label="Register"
                    style={{ width: '150px' }}
                />
            )}
        </>
    )

    const renderResults = ({ success = false }) => (
        <>
            <h3>{success ? 'Completed!' : 'Registration Failed'}</h3>
            <div className='registration-description'>
                {success
                    ? (
                        <>
                            <span>You've gotten your EthereumPow domain names!</span>
                            <MarketLink useContextMenu={!isMobile} />
                        </>
                    )
                    : 'Confirm the registration, perform payment and complete the registration. Please note that if the second transaction is not processed within 7 days after the first, the registration will be forfeited and has to be restarted from the first step.'
                }
            </div>
            {success
                ? (
                    <div className='sample-images'>
                        {selectedRegistrars.map(({ basename }) => (
                            <div key={basename} className={`nft-image ${basename}`}><span>{renderDomain(`${name}.${basename}`)}</span></div>
                        ))}
                    </div>
                )
                : renderDomainPills()
            }
            <div className='btn-group'>
                {success
                    ? (
                        <Link to='/mydomains'>
                            <div className={`btn btn-primary`}>Manage</div>
                        </Link>
                    )
                    : (
                        <LoadingButton
                            className='btn-primary'
                            actionHandler={handleRegister}
                            label="Try Again"
                            style={{ width: '150px' }}
                        />
                    )
                }
            </div>
        </>
    );

    return (
        <div className='registration'>
            {
                (!isMobile && step !== 5) && (
                    <div className='registration-steps'>
                        <div className={(step === 1 || step === 2) ? 'active' : ''}><span>Step 1</span><br/>Request to<br/>Register</div>
                        <div className={(step === 3 || step === 4) ? 'active' : ''}><span>Step 2</span><br/>Confirm<br/>Registration</div>
                        <div className={(step === 5) ? 'active' : ''}><span>Step 3</span><br/>Registration<br/>Complete</div>
                    </div>
                )
            }
            <div className='registration-content'>
                { step === 5 ? renderResults(registration) : renderRegistration() }
                {(step === 1 && params.length === 0 && isMobile) && <div className="icon icon-back" onClick={handleBack} />}
            </div>
        </div>
    )
};

export default RegistrationWizard;
