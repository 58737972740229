import { ethers } from "ethers";
import { useEffect, useCallback, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useContract, useSigner } from "wagmi";

import { AccountContext } from '../hooks/AccountContext';
import useSupportedNetwork from "../hooks/useSupportedNetwork";

import { MYSTERY_BOX_ABI } from "../constants/abis";
import { MYSTERY_BOX_ADDR } from "../constants/contracts";
import computeProof from "../helpers/computeProof";

import LoadingButton from "./LoadingButton";
import MarketLink from "./MarketLink";
import Loading from "./Loading";

import './css/mystery-box.css';

const MysteryBox = () => {
    const { isConnected, address } = useContext(AccountContext);
    const { data: signer } = useSigner();
    const [mysteryBoxState, setMysteryBoxState] = useState({});
    const { isWrongNetwork, renderSwitchButton } = useSupportedNetwork();

    const mysteryBoxContract = useContract({
        addressOrName: MYSTERY_BOX_ADDR,
        contractInterface: MYSTERY_BOX_ABI,
        signerOrProvider: signer,
    });

    const checkStatus = useCallback(async () => {
        const [claimStatus, price, mintedCount, tokenBalance, canOpen, currentId] = await Promise.all([
            await mysteryBoxContract.status(),
            await mysteryBoxContract.price(),
            await mysteryBoxContract.numberMinted(address),
            await mysteryBoxContract.balanceOf(address),
            await mysteryBoxContract.canOpen(),
            await mysteryBoxContract.currentId(),
        ]);
        const proof = computeProof(address);
        const isAllowlisted = proof.length > 0;
        const isClaimed = mintedCount && mintedCount.toNumber() === 2;
        const isSoldOut = currentId && currentId.toNumber() > 22000;

        const hasClaimable = !isSoldOut && !isClaimed && ((claimStatus === 1 && isAllowlisted) || (claimStatus === 2));
        const balance = tokenBalance && tokenBalance.toNumber();
        let tokenIds = [];
        if (balance > 0) {
            const resps = await Promise.all(
                [...Array(balance).keys()].map(
                    async (index) => await mysteryBoxContract.tokenOfOwnerByIndex(address, index)
                )
            );
            tokenIds = resps.map((id) => id.toNumber());
        }

        setMysteryBoxState({
            balance,
            canOpen,
            claimStatus,
            hasClaimable,
            isAllowlisted: proof.length > 0,
            isClaimed,
            isSoldOut,
            mintedCount,
            price,
            proof,
            tokenIds,
        });
    }, [address, mysteryBoxContract]);

    useEffect(() => {
        if (isConnected && signer) {
            checkStatus();
        }
    }, [isConnected, signer, checkStatus]);

    useEffect(() => {
        return () => localStorage.setItem('hasOpenMysteryBox', true);
    }, []);

    const handlePublicClaim = useCallback(async () => {
        if (!isConnected) return;
        const resp = await mysteryBoxContract.publicClaim(2, { value: mysteryBoxState.price.mul(2) });
        // const resp = await mysteryBoxContract.publicClaim(2);
        await resp.wait();
        checkStatus();
    }, [mysteryBoxContract, isConnected, checkStatus, mysteryBoxState.price]);

    const handleAllowlistClaim = useCallback(async () => {
        if (!isConnected || !mysteryBoxState.isAllowlisted) return;
        const resp = await mysteryBoxContract.allowlistClaim(2, mysteryBoxState.proof, { value: mysteryBoxState.price.mul(2) });
        // const resp = await mysteryBoxContract.allowlistClaim(2, mysteryBoxState.proof);
        await resp.wait();
        checkStatus();
    }, [mysteryBoxContract, isConnected, mysteryBoxState.isAllowlisted, mysteryBoxState.proof, checkStatus, mysteryBoxState.price]);
    // }, [mysteryBoxContract, isConnected, mysteryBoxState.isAllowlisted, mysteryBoxState.proof, checkStatus]);

    const makeHandlOpen = useCallback((id) => async () => {
        const trx = await mysteryBoxContract.open(id);
        await trx.wait();
        checkStatus();
    }, [mysteryBoxContract, checkStatus])

    const renderClaim = () => {
        const {
            claimStatus,
            isAllowlisted = false,
            mintedCount = 0,
            price = 0,
            hasClaimable,
        } = mysteryBoxState;
        const hasClaimed = mintedCount && mintedCount.toNumber() === 2;

        return (
            <>
                <div className='mystery-box-description'>
                    <div>
                        {claimStatus !== 2 && isAllowlisted && <span className="icon icon-checked"></span>}
                        {claimStatus !== 2 && (isAllowlisted ? 'You have been whitelisted, congratulations!' : 'You were not selected in the whitelist!')}
                        {claimStatus === 2 && 'Claim your Mystery Box'}
                    </div>
                </div>

                <div className="image img-mysterybox"></div>

                {claimStatus === 0 && (
                    <div className="btn btn-primary disabled">Not Started</div>
                )}
                {claimStatus === 1 && (
                    <LoadingButton
                        className={`btn-primary${!hasClaimable ? ' disabled' : ''}`}
                        actionHandler={hasClaimable ? handleAllowlistClaim : null}
                        label={hasClaimed ? 'Claimed' : (isAllowlisted ? 'Claim' : 'Not Eligible')}
                        style={{ width: '150px' }}
                    />
                )}
                {claimStatus === 2 && (
                    <LoadingButton
                        className={`btn-primary${hasClaimed ? ' disabled' : ''}`}
                        actionHandler={!hasClaimed ? handlePublicClaim : null}
                        label={hasClaimed ? 'Claimed' : 'Claim'}
                        style={{ width: '150px' }}
                    />
                )}

                <div className="mint-info">
                    Claimed <span className="emphasize">{
                        mintedCount ? mintedCount.toNumber() : '0'
                    }</span>&nbsp;&nbsp;Available <span className="emphasize">{hasClaimable ? '2' : '0'}</span><br/>
                    Mysterbox Mint Price: {ethers.utils.formatEther(price)} ETHW each
                </div>
            </>
        )
    };

    const renderOpen = () => {
        const { tokenIds, balance, canOpen } = mysteryBoxState;
        const firstId = tokenIds[0];
        return (
            <>
                <div className='mystery-box-description'>
                    {canOpen
                        ? <div>You can reveal your Super Mysterybox{balance > 1 ? 'es' : ''} now!</div>
                        : <div>You have {balance} Super Mysterybox{balance > 1 ? 'es' : ''}!</div>
                    }
                </div>
                <div className="image img-mysterybox"></div>
                {/* <div className="mystery-box-open-section">
                    {
                        tokenIds.map((id) => (
                            <div className="mystery-box-open-container" key={id}>
                                <div className="image img-mysterybox"></div>
                                <div className="btn btn-secondary" onClick={makeHandlOpen(id)}>Open</div>
                                <p>Box #{id}</p>
                            </div>
                        ))
                    }
                </div> */}

                <LoadingButton
                    className={`btn-primary${canOpen ? '' : ' disabled'}`}
                    actionHandler={canOpen ? makeHandlOpen(firstId) : null}
                    label={`Reveal #${firstId}`}
                    style={{ width: '200px' }}
                />

                <div className="mint-info">
                    Available for reveal <span className="emphasize">{balance}</span>
                </div>
            </>
        );
    }

    const renderDefault = () => {
        const { isClaimed, mintedCount, isSoldOut } = mysteryBoxState;
        const count = mintedCount ? mintedCount.toNumber() : 0;

        return (
            <>
                <div className='mystery-box-description'>
                    <div>{isClaimed ? 'You\'ve gotton your EthereumPoW domain name!' : (isSoldOut ? 'Mystery Box is Sold Out.' : 'No Mystery Box is available.')}</div>
                    <MarketLink />
                </div>

                <div className="image img-mysterybox"></div>

                <Link to="/mydomains">
                    <div className="btn btn-primary">Manage</div>
                </Link>

                <div className="mint-info">
                    Claimed <span className="emphasize">{count}</span>&nbsp;&nbsp;Available <span className="emphasize">0</span>
                </div>
            </>
        );
    }

    const renderMysteryBox = () => {
        const { hasClaimable = false, tokenIds = [] } = mysteryBoxState;
        if (hasClaimable) return renderClaim();
        else if (tokenIds.length) return renderOpen();

        return renderDefault();
    }

    if (isWrongNetwork) {
        return renderSwitchButton();
    }

    if (isConnected && mysteryBoxState.claimStatus === undefined) {
        return (<Loading />);
    }
    
    return (
        <div className="mystery-box">
            <div className="mystery-box-container">
                <Link className="link-close" to="/"><span className="icon icon-close"></span></Link>
                <h3>Super Mysterybox</h3>

                {renderMysteryBox()}
            </div>
        </div>
    )
};

export default MysteryBox;