import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { Routes, Route, BrowserRouter } from "react-router-dom";

import { AccountContextProvider } from "./hooks/AccountContext.js";
import { chain } from "./constants/chains.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Main from "./components/Main.js";
import MyDomains from "./components/MyDomains.js";
import MysteryBox from "./components/MysteryBox.js";
import TwitRegisterPage from "./components/TwitRegisterPage.js";

import './App.css';

const { chains, provider } = configureChains(
	Object.values(chain),
	[jsonRpcProvider({
		rpc: () => {
			return { http: 'https://mainnet.ethereumpow.org' }
		},
	}), publicProvider()]
);
const { connectors } = getDefaultWallets({
	appName: "WENS",
	chains
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider
});

function App() {

	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains}>
				<AccountContextProvider>
					<BrowserRouter>
						<div className="app">
							<Header />
							<div className="content">
								<Routes>
									<Route path="/mysterybox" element={<MysteryBox />} />
									<Route path="/mydomains" element={<MyDomains />} />
									<Route path="/twit" element={<TwitRegisterPage />} />
									<Route path="*" element={<Main />} />
								</Routes>
							</div>
							<Footer />
						</div>
					</BrowserRouter>
				</AccountContextProvider>
			</RainbowKitProvider>
		</WagmiConfig>
	);
}

export default App;