
import './css/switch-network.css';

const SwitchNetwork = ({ handleSwitchNetwork }) => (
    <div className="switch-network-container">
        <h2 className='switch-title'>Unsupported Network</h2>
        <p className='switch-text'>Please change your dapp browser to ETHW Mainnet to continue</p>
        <div className="btn btn-primary" onClick={handleSwitchNetwork}>Switch Network</div>
    </div>
);

export default SwitchNetwork;