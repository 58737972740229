import { useCallback } from "react";
import { useNetwork, useSwitchNetwork } from "wagmi";

import SwitchNetwork from "../components/SwitchNetwork";

import { targetChainId } from "../constants/chains";

const useSupportedNetwork = () => {
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();
    const isWrongNetwork = chain && chain.id !== targetChainId;

    const handleSwitchNetwork = useCallback(() => {
        switchNetwork(targetChainId);
    }, [switchNetwork]);

    const renderSwitchButton = () => (
        <SwitchNetwork handleSwitchNetwork={handleSwitchNetwork} />
    );

    return { isWrongNetwork, handleSwitchNetwork, renderSwitchButton };
};

export default useSupportedNetwork;