export const WENS_CONTROLLER_ADDR = '0xA5AC8a00fFAc2014f1D4e5b11b234DCd9f702811';
export const RESOLVER_ADDR = '0x74fC211a8bd0Cb6ad57a2DD2a47ffC5b6417a822';
export const MYSTERY_BOX_ADDR = '0x2928A445D4a76cb0811c952ef85d2d39F7c79414';
export const REVERSE_REGISTRAR_ADDR = '0x3D67dCD20225Ee7a96b5C084c818B802c41DBA24';
export const REVERSE_RESOLVER_ADDR = '0x84b50Acc16d8dB6A7B48e688fcb18AC5DF2EA4bC';
export const TWIT_REGISTER_ADDR = '0xb090E595bddf544F8c7E295A93aaB1c04707cfce';

export const REGISTRARS = [
    { basename: 'ethw', address: '0x9feb3ab8a40B620B68A112EA0f82C6cF06B5c6fB' },
    { basename: 'awsb', address: '0x9e32b72f8c8C97D1C2FAB030Ad83b65a74516A80' },
    { basename: 'ape', address: '0xf5B60bdC04b558A78f9B8DBE45F107BE2FC71c1b' },
    { basename: 'pow', address: '0x00f3fab319453407Dd1B05740f3De7C73235ab67' },
    { basename: 'wens', address: '0xe857F2661DF1398d416528DAb80557d10F3664Db' },
    { basename: 'twit', address: '0xF6EAC0712c52433451a6BBCF0eF576fa7e44bCe1' },
];