import {
    useContract,
    useSigner,
} from 'wagmi';

import { REGISTRARS } from '../constants/contracts';
import { REGISTRAR_ABI } from '../constants/abis';

const useNFTContracts = () => {
    const { data: signer } = useSigner();

    const ethwContract = useContract({
        addressOrName: REGISTRARS[0].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const awsbContract = useContract({
        addressOrName: REGISTRARS[1].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const apeContract = useContract({
        addressOrName: REGISTRARS[2].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const powContract = useContract({
        addressOrName: REGISTRARS[3].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const wensContract = useContract({
        addressOrName: REGISTRARS[4].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });
    const twitContract = useContract({
        addressOrName: REGISTRARS[5].address,
        contractInterface: REGISTRAR_ABI,
        signerOrProvider: signer,
    });

    return {
        ape: apeContract,
        awsb: awsbContract,
        ethw: ethwContract,
        pow: powContract,
        wens: wensContract,
        twit: twitContract,
    };
};

export default useNFTContracts;
