import { useCallback, useState } from "react";
import {useLocation} from "react-router-dom";
import { useContract, useSigner, useAccount } from "wagmi";
import { useConnectModal } from '@rainbow-me/rainbowkit';

import useSupportedNetwork from "../hooks/useSupportedNetwork";

import RegistrationWizard from "./RegistrationWizard";

import { TWIT_REGISTER_ADDR, REGISTRARS } from "../constants/contracts";
import { TWIT_REGISTER_ABI } from "../constants/abis";
import { renderDomain, validateDomain } from "../helpers/domain-helpers";

import './css/main.css';

const TWIT_REGISTRAR = REGISTRARS.filter((r) => r.basename === 'twit');

const TwitRegisterPage = () => {
    const { isConnected } = useAccount();
    const { data: signer } = useSigner();
    const { isWrongNetwork, renderSwitchButton } = useSupportedNetwork();
    const { openConnectModal } = useConnectModal();
    const queryParams = new URLSearchParams(useLocation().search)
    const [name, setName] = useState(queryParams.get('name'));
    const [isNameAvailable, setIsNameAvailable] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(new Set());
    const [modalOpen, setModalOpen] = useState(false);
    const [validationError, setValidationError] = useState('');
    
    const isSearchDisabled = !Boolean(name);
    const twitContract = useContract({
        addressOrName: TWIT_REGISTER_ADDR,
        contractInterface: TWIT_REGISTER_ABI,
        signerOrProvider: signer,
    });

    const resetSearch = useCallback(() => {
        setIsNameAvailable([]);
        setSelectedIndex(new Set());
        setValidationError('');
    }, []);

    const handleNameChange = useCallback((e) => {
        let newValue = e.target.value;
        if (newValue) newValue = newValue.toLowerCase();
        setName(newValue);
        resetSearch();
    }, [resetSearch]);

    const handleSearch = useCallback(async () => {
        if (!isConnected) {
            openConnectModal();
            return;
        }

        if (isSearchDisabled || !twitContract) return;

        const newValidationError = validateDomain(name);
        if (newValidationError) {
            setValidationError(newValidationError);
            return;
        }

        try {
            const contractStatus = await twitContract.status();
            if (contractStatus !== 0) {
                setValidationError('Registration is not open right now.');
                return;
            }
            const newChecks = await twitContract.available(String(name))
            console.log('QA: check', newChecks);
            setIsNameAvailable([newChecks]);
        } catch (error) {
            console.log({ error });
        }
    }, [twitContract, name, isSearchDisabled, isConnected, openConnectModal]);

    const handleKeyPress = useCallback((event) => {
        if(event.key === 'Enter'){
            handleSearch();
        }
    }, [handleSearch]);

    const handleSelect = useCallback((index) => () => {
        if (selectedIndex.has(index)) {
            selectedIndex.delete(index);
        } else {
            selectedIndex.add(index);
        }

        setSelectedIndex(new Set(selectedIndex));
    }, [selectedIndex]);

    const toggleRegistrationWizard = useCallback(() => {
        setModalOpen(!modalOpen);
    }, [modalOpen]);

    const renderSelection = ({ basename }, index) => {
        const isAvailable = isNameAvailable[index];
        const isSelected = selectedIndex.has(index);

        return (
            <div
                className={`basename-select-item
                    ${isAvailable ? ' available' : ''}
                    ${isSelected ? ' selected' : ''}`
                }
                key={basename}
                onClick={isAvailable ? handleSelect(index) : null}
            >
                <div className="basename-select-item-left">
                    <span className="icon icon-selection"></span>
                    <span>{renderDomain(`${name}.${basename}`, 60)}</span>
                </div>
                <div className="basename-select-item-right">
                    {isAvailable
                        ? <div
                            className={`btn btn-secondary`}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </div>
                        : <span className="availability">Unavailable</span>
                    }
                </div>
            </div>
        );
    }

    if (modalOpen) {
        return (
            <RegistrationWizard
                contract={twitContract}
                isOpen={modalOpen}
                name={name}
                selectedIndex={selectedIndex}
                closeModal={toggleRegistrationWizard}
                resetSearch={resetSearch}
                registrars={TWIT_REGISTRAR}
                isSingleDomain
                isDurationEditable={false}
            />
        );
    }

    return (
        <div className="main">
            {
                isWrongNetwork
                ? renderSwitchButton()
                :<>
                    <div className="section application">
                        <h1 className="hero-title">EthereumPoW</h1>
                        <h2 className="hero-sub-title">Name Service</h2>
                        <p className="bullet-points">
                            <span className="point-item"><span className="check-mark"></span>On-Chain</span>
                            <span className="point-item"><span className="check-mark"></span>Decentralized</span>
                            <span className="point-item"><span className="check-mark"></span>Open Source</span>
                            <span className="point-item"><span className="check-mark"></span>Self-Sovereign</span>
                        </p>
                        <p>Only pure digit .twit domains are available for registration. The cost is 0.01ETHW.</p>
                        <div className="input-group">
                            <input
                                className="value-input name-input"
                                placeholder="get your pure digit .twit domain"
                                value={name}
                                onChange={handleNameChange}
                                onKeyPress={handleKeyPress}
                            />
                            <div className="icon icon-magnifier"></div>
                            <div className={`btn btn-primary${isSearchDisabled ? ' disabled' : ''}`} onClick={handleSearch}>Search</div>
                        </div>
                        {validationError && (
                            <div className="validation-error">
                                <span className="icon icon-warning"></span>
                                <span>{validationError}</span>
                            </div>
                        )}
                        {isNameAvailable.length !== 0 &&
                            <div className="basename-select">
                                {TWIT_REGISTRAR.map(renderSelection)}
                            </div>
                        }
                    </div>

                    <div className="section btn-group">
                        {isNameAvailable.length !== 0 &&
                            <div
                                className={`btn btn-primary${!selectedIndex.size ? ' disabled' : ''}`}
                                onClick={toggleRegistrationWizard}
                            >
                                Register
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
};

export default TwitRegisterPage;