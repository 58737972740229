import { chain as wagmiChain } from "wagmi";

export const chain = {
    ...wagmiChain,
    ethwMainnet: {
        blockExplorers: {
            default: {
                name: 'oklink',
                url: 'https://www.oklink.com/en/ethw'
            }
        },
        id: 10001,
        name: 'ETHW Mainnet',
        nativeCurrency: {
            name: 'EtherPoW',
            symbol: 'ETHW',
            decimals: 18,
        },
        network: 'ethereumpow',
        rpcUrls: {
            default: 'https://mainnet.ethereumpow.org',
        }
    },
};

export const targetChainId = 10001;