import { MerkleTree } from "merkletreejs";
import { keccak256 } from "@ethersproject/keccak256";

import { whitelist } from "../constants/whitelist";

const leafNodes = whitelist.map((addr) => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const computeProof = (address) => {
    const leaf = keccak256(address.toLowerCase());
    return merkleTree.getProof(leaf).map(p => `0x${p.data.toString("hex")}`);
}

export default computeProof;
