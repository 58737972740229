
import { useState, useEffect, useRef } from "react";

import './css/market-link.css';

const menuItems = [
  {
    icon: 'icon-openw',
    text: 'OPENW',
    link: 'https://www.openwnft.xyz/#/account?navTabsindex=Collected'
  },
  {
    icon: 'icon-nuwton',
    text: 'NUWTON',
    link: 'https://nuwton.io/account'
  },
  {
    icon: 'icon-nswap',
    text: 'NSWAP',
    link: 'https://www.nswap.com/user'
  },
];

const MarketLink = ({ tether = 'down', useContextMenu = true }) => {
  const ref = useRef(null);
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    if (useContextMenu) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowLinks(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [useContextMenu]);

  const renderIconMenu = () => (
    <div className="menu">
      {menuItems.map(({ icon, text, link }, index) => (
        <a key={text} className='menuItem' href={link} target="_blank" rel="noreferrer">
            <div className={`icon ${icon}`} />
        </a>
      ))}
    </div>
  );

  return (
    <div className="marketplace-container">
      <div ref={ref} className={`marketplace ${tether}`} onClick={ () => { useContextMenu ? setShowLinks(true) : setShowLinks(!showLinks) }}>
        <div className="marketplace-link">
          Go to Marketplace
          <span className={`icon ${ useContextMenu || !showLinks ? 'icon-right-arrow' : 'icon-down-arrow'}`} />
          <div className='context-menu' style={{ display: useContextMenu && showLinks ? 'block' : 'none' }}>
            {menuItems.map(({ icon, text, link }, index) => (
              <a key={text} className='context-menu-item' href={link} target="_blank" rel="noreferrer">
                  <div className={`icon ${icon}`} />
                  <div>{text}</div>
              </a>
            ))}
          </div>
        </div>
        {
          !useContextMenu && showLinks ? renderIconMenu() : ''
        }
      </div>
    </div>
  )
}

export default MarketLink;